<template>
  <div>
    <div v-if="quren == 1" class="panel demoWrapper">
      <div class="tabtitle">
        <div class="title-left">
          <span style="font-size: 18px">Invoice Info List</span>
        </div>
      </div>
      <div class="search-form">
        <div style="display: flex">
          <el-form
            :model="formLabelAlign"
            label-position="right"
            :inline="true"
          >
            <el-form-item label="Enterprise Number" prop="enterpriseNumber">
              <el-input
                v-model.trim="formLabelAlign.id"
                placeholder="Please Enter"
                size="mini"
                oninput="this.value=this.value.replace(/[^0-9\.]/g,'')"
                maxlength="9"
              />
            </el-form-item>
            <el-form-item label="Enterprise Name" prop="enterpriseName">
              <el-input
                v-model.trim="formLabelAlign.enterpriseName"
                placeholder="Please Enter"
                size="mini"
              />
            </el-form-item>
            <el-form-item label="License Type">
              <el-select
                v-model.trim="formLabelAlign.licenseType"
                placeholder="Please Enter"
                size="mini"
              >
                <el-option label="Business License" value="0" />
              </el-select>
            </el-form-item>

            <div v-show="boxShow">
              <el-form-item label="VAT lnvoice Audit Status">
                <el-select
                  v-model.trim="formLabelAlign.invoiceInfoAuditState"
                  placeholder="Please Enter"
                  size="mini"
                >
                  <el-option
                    v-for="item in discountTypeList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
              <el-form-item label="License No">
                <el-input
                  v-model.trim="formLabelAlign.licenseNumber"
                  size="mini"
                  placeholder="Please Enter"
                />
              </el-form-item>
              <el-form-item label="Validity Type">
                <el-select
                  v-model.trim="formLabelAlign.state"
                  placeholder="Please Enter"
                  size="mini"
                >
                  <el-option
                    v-for="item in validityType"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
            </div>
          </el-form>
          <div class="buttom-top">
            <el-button
              type="text"
              size="small"
              class="qihuan"
              @click="businessShow"
            >
              <span>MoreOptions</span>
              <i
                :class="
                  boxShow == true ? 'el-icon-arrow-up' : 'el-icon-arrow-down'
                "
              />
            </el-button>
            <el-button size="small" style="margin-left: 60px" @click="reset">
              Reset
            </el-button>
            <el-button
              type="primary"
              icon="el-icon-search"
              size="small"
              @click="searchBtn()"
            >
              Search
            </el-button>
          </div>
        </div>
      </div>
      <div class="panel-body">
        <el-table
          class="tableBox"
          :data="tableData"
          style="width: 100%"
          :header-cell-style="{
            background: '#EBF1FF'
          }"
        >
          <!-- 修改无数据时 提示文字 -->
          <template slot="empty" class="logo_no">
            <!-- <img :src="emptyImgSrc" alt="" width="118px" height="110px"> -->
            <p style="color: #969799">No more data</p>
          </template>
          <!-- <el-table-column prop="id" label="Enterprise Number" min-width="120" /> -->
          <el-table-column
            prop="id"
            label="Enterprise Number"
            min-width="120"
          >
            <template slot-scope="scope">
              <el-link
                type="primary"
                :underline="false"
                style="width: 120px"
                size="small"
                @click="goLink(scope.row)"
              >
                {{ scope.row.id }}
              </el-link>
            </template>
          </el-table-column>

          <el-table-column prop="enterpriseName" label="Enterprise Sign" width="160" />

          <el-table-column
            label="Enterprise Type"
            prop="enterpriseType"
            min-width="160"
          >
            <template slot-scope="scope">
              <font v-if="scope.row.enterpriseType == 1">listed company</font>
              <font v-if="scope.row.enterpriseType == 2">
                individual enterprise
              </font>
              <font v-if="scope.row.enterpriseType == 3">
                state-owned business
              </font>
              <font v-if="scope.row.enterpriseType == 4">
                cooperative partner
              </font>
              <font v-if="scope.row.enterpriseType == 5"> foundation</font>
              <font v-if="scope.row.enterpriseType == 6">
                social institution
              </font>
              <font v-if="scope.row.enterpriseType == 7">
                governmental agencies
              </font>
              <font v-if="scope.row.enterpriseType == 8">rests </font>
            </template>
          </el-table-column>
          <el-table-column
            prop="licenseType"
            label="License Type"
            min-width="160"
          >
            <template slot-scope="scope">
              <font v-if="scope.row.licenseType === 0">
                Business Liscense
              </font>
            </template>
          </el-table-column>
          <el-table-column
            prop="licenseNumber"
            label="License No"
            min-width="120"
          />
          <el-table-column
            label="VAT Audit Status"
            prop="invoiceInfoAuditState"
            min-width="140"
          >
            <template slot-scope="scope">
              <font
                v-if="scope.row.invoiceInfoAuditState == 1"
                style="font-size: 14px"
              >
                <i class="el-icon-success" style="color: #40c740" />Passed
              </font>
              <font v-else-if="scope.row.invoiceInfoAuditState == 0">
                <i class="el-icon-time" style="color: black" />Waiting Audit
              </font>
              <font v-else color="#FA5050">
                <i
                  class="el-icon-circle-close"
                  style="color: #fa5050"
                />Rejected
              </font>
            </template>
          </el-table-column>
          <el-table-column label="Validity Type" prop="state" min-width="120">
            <template slot-scope="scope">
              <font v-if="scope.row.state == 0">
                <i class="el-icon-success" style="color: #40c740" />
                Valid
              </font>
              <font v-if="scope.row.state == 1">
                <i class="el-icon-error" style="color: #fa5050" />
                Invalid
              </font>
            </template>
          </el-table-column>
        </el-table>
        <div class="pageWrapper">
          <el-pagination
            :current-page="currentPage"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="pagesize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
      </div>
    </div>
    <div v-if="quren == 2">
      <invoiceinfo :obj="obj" @get-quren="parentQuren" />
    </div>
  </div>
</template>

<script>
import invoiceinfo from './invoiceinfo.vue'
import { getBannerList } from '@/api/enterprise/enterprise';
export default {
  components:{invoiceinfo},
  data() {
    return {
      emptyImgSrc: '',
      input3: '',
      quren: '1',
      labelPosition: 'top',
      formLabelAlign: {
        state: '',
        licenseType: '',
        invoiceInfoAuditState: ''
      },
      total: 0,
      rules: {},

      select: {},
      boxShow: false,
      title: '', //  点击添加/edit传给子组件的标题名称
      input1: '', //  筛选中合同ID
      value: '', //  筛选中状态
      date1: '',
      value1: '', //  筛选中折扣率类型
      currentPage: 1,
      pagesize: 10,
      discountTypeList: [
        {
          value: 0,
          label: 'Waiting Audit'
        },
        {
          value: 1,
          label: 'Passed'
        },
        {
          value: 2,
          label: 'Rejected'
        }
      ],
      validityType: [
        {
          value: 0,
          label: 'Valid'
        },
        {
          value: 1,
          label: 'Invalid'
        }
      ],
      tableData: []
    };
  },
  created() {
    this.getDataList();
    this.emptyImgSrc = '';
  },
  methods: {
    // 展开收起筛选部分2
    businessShow() {
      this.boxShow = !this.boxShow;
    },
    // 合同号particulars页面跳转
    goLink(row) {
      console.log(row);
      // this.obj = row;
      // this.quren = '2';
      this.$router.push({
        path: 'invoiceinfo',
        query: {
          id: row.id
        }
      });
    },
    // 新增折扣
    addDiscountFun() {
      this.quren = '2';
      this.title = '创建折扣';
    },
    // 企业信息审核页面按钮
    redact() {
      // 赋值给表单--
    },
    // 子组件点击返回传给父组件的quren
    parentQuren(data) {
      //console.log(data, 'quren');
      this.$nextTick(() => {
        this.quren = data;
      });
      //加载列表------------111
    },
    // 重置搜索筛选条件
    reset() {
      this.formLabelAlign = {};
      this.currentPage=1;
      this.pagesize=10;
      this.getDataList();
      // 加载列表------------111
    },
    // 页面查询接口
    searchBtn() {
      this.tableData = [];
      this.loading = true;
      // 调用查询筛选接口-------------
      this.getDataList();
      // getBannerList(this.formLabelAlign).then(res => {
      //   this.loading = false;
      //   //console.log(res);
      //   if (res.code === 200) {
      //     this.tableData = res.data.rows;
      //     this.total = res.data.total;
      //   }
      // });
    },
    // 页面查询接口
    getDataList() {
      this.loading = true;
      // 调用查询筛选接口-------------
      let par = {enterpriseInfoDTO: this.formLabelAlign, pageSize: this.pagesize, pageNo: this.currentPage };
      getBannerList(par).then(res => {
        this.loading = false;
        if (res.success && res.code === 200) {
          this.tableData = res.data.dataList;
          this.total  = res.data.totalCount;
        } else {
          this.tableData = [];
          this.total  = 0;
          this.$message({
            message: res.msg,
            type: 'error'
          });
        }
      });
    },
    // 获取折扣率类型下拉框选项值-----
    // 获取折扣率类型下拉框选项值-------------
    getDiscountTypeList() {},

    // 导出---------------
    exportState() {},
    // 合同只能输入字母加数字
    accountInput(val) {
      let codeReg = new RegExp('[A-Za-z0-9]+'); //正则：字母加数字组合
      let len = val.length;
      let str = '';
      for (let i = 0; i < len; i++) {
        if (codeReg.test(val[i])) {
          str += val[i];
        }
      }
      this.input1 = str;
    },

    // 分割线===================================================
    // 添加企业信息
    addState() {
      //  this.$router.push({
      //    path:''
      //  })
    },
    handleSizeChange(val) {
      this.pagesize = val; //console.log(`每页 ${val} 条`);
      this.getDataList();
    },
    handleCurrentChange(val) {
      this.currentPage = val; //console.log(`当前页: ${val}`);
      this.getDataList();
    }
  }
};
</script>
<style lang="less" scoped>
.demoWrapper {
  .el-input {
    width: 200px;
    margin-right: 30px;
  }
  .el-pagination__editor.el-input {
    width: 50px;
  }
  .pageWrapper {
    margin: 10px 10px 10px 0;
    float: right;
    height: 45px;
  }

  .tabtitle {
    position: relative;
    height: 68px;
    line-height: 68px;
    border-bottom: 1px solid rgba(183, 188, 201, 0.3);
    .title-left {
      position: absolute;
      left: 0px;
      padding-left: 16px;
      font-size: 20px;
      font-weight: Bold;
      color: rgba(36, 37, 38, 1);
    }
  }
  .search-form {
    width: 100%;
    height: 100%;
    padding: 24px 16px 0;
    font-size: 14px;
    line-height: 0;
    /deep/.el-input__inner {
      height: 32px;
      line-height: 32px;
      font-size: 14px;
    }
    .buttom-top {
      width: 40%;
      height: 100px;
      line-height: 100px;
      padding-top: 6px;
      padding-left: 40px;
      .el-button {
        width: 100px;
        height: 32px;
        border: 1px solid rgba(199, 204, 210, 1);
        border-radius: 4px;
        margin-top: 35px;
      }
      .qihuan {
        border: 0;
      }
      .qihuan {
        border: 0;
      }
    }
    .el-form-item {
      width: 200px;
      margin-left: 20px;
      .input-with-select {
        .input-with-select {
          height: 116%;
          font-size: 11px;
        }
      }
      .el-form-item__content {
        line-height: 32px;
      }
    }
    .el-form--label-top .el-form-item__label {
      padding: 0;
      .el-select > .el-input__inner {
        padding: 0px;
      }
    }
  }
  .el-input-group__prepend div.el-select .el-input__inner {
    padding: 0px;
  }
  .panel-body {
    padding-left: 16px;
    padding-right: 16px;
  }
}
// 表格无数据显示样式
.data-pic-img {
  width: 100%;
  height: 238px;

  margin: 0 auto;
  .data-pic {
    width: 92px;
    height: 92px;
    position: relative;
    top: 50%; /*偏移*/
    transform: translateY(-50%);
    .No-message {
      padding-top: 35px;
    }
  }
}
/deep/.el-button {
  // padding: 0 12px !important;
  width: 100px;
  font-family: ArialMT;
  font-size: 14px;
}

::v-deep .el-button--primary {
  color: #fff;
  background-color: #2262ff;
  border-color: #2262ff;
}
::v-deep .el-button--text {
  color: #2262ff;
}

::v-deep .el-link--primary {
  color: #2262ff;
}

::v-deep .el-radio-group {
  .el-radio__label {
    color: #242526;
  }
  .is-checked {
    .el-radio__inner {
      background-color: #2262ff;
    }
    .el-radio__label {
      color: #242526;
    }
  }
}

::v-deep .el-table {
  .cell {
    // white-space: pre-wrap;
    word-break: keep-all;
  }
}

::v-deep .el-pager {
  li {
    border: 0;
  }
}
</style>
